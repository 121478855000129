<template>
  <div style="position: relative">
    <LeadsHeader />
    <LeadDetails :leadId="$route.params.id" />
    <b-container class="mb-4">
      <h3>Notes:</h3>
      <LeadNoteForm :leadId="$route.params.id" ref="leadNoteForm" class="my-4" />
      <LeadNotes :leadId="$route.params.id" />
    </b-container>
  </div>
</template>

<script>
import LeadsHeader from './LeadsHeader'
import LeadDetails from '@/components/lead/LeadDetails'
import LeadNotes from '@/components/lead/LeadNotes'
import LeadNoteForm from '@/components/lead/LeadNoteForm'

export default {
  name: "LeadDeatails.vue",
  components: { LeadsHeader, LeadDetails, LeadNotes, LeadNoteForm }
};
</script>